import React from 'react'
import Chicken from '../components/chicken'


const ChickenPage = () => {
  return (
    <div><Chicken/></div>
  )
}

export default ChickenPage